a {
  color: #000;
  text-decoration: none;
}

p {
  margin: 0;
}

.deepmindsystems {
  position: absolute;
  bottom: 10px;
  width: 100%;
  line-height: 30px;
  text-align: center;
  overflow: hidden;
}

.deepmindsystems img {
  width: 50px;
}

@media (max-width: 600px) {
  .deepmindsystems img {
    margin-left: 5px;
  }
}





.dms-name.small {
  font-size: 1.3em;
  display: none;
}

.dms-name.expanded {
  display: block;
  font-size: 1.3em;
}

.makeStyles-drawerPaperClose-20 .dms-name.expanded {
  display: none !important;
}

.makeStyles-drawerPaperClose-20 .dms-name.small {
  display: block !important;
}

/*# sourceMappingURL=adminlte.css.map */

.tox-notifications-container {
  display: none;
}